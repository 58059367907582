////////// COLORS Bootstrap //////////
$pink:    #d70060;
$purple:  #3c255e;
$orange:  #f18d05;
$green:   #4fab89;
$dark:    #1b0320;
$light:   #f5f5f5;
$theme-colors: (
    "primary": $pink,
);
// .bg-light {
//     background-color: #ede9f1 !important;
// }
// Fonts
$primary-font:   'Open Sans', sans-serif;
$secondary-font: 'Dosis', sans-serif;

$font-family-sans-serif:  $primary-font;
$headings-font-family:    $secondary-font;
$btn-font-family:         $secondary-font;

////////// BORDERS Bootstrap //////////
$border-radius-lg: 2rem;

$btn-border-radius:     $border-radius-lg;
$btn-border-radius-lg:  $border-radius-lg;
$btn-border-radius-sm:  $border-radius-lg;

////////// NAVBAR //////////
$navbar-height: 70px;

a.nav-link {
  cursor: pointer;
}

////////// SIDEBAR //////////
$sidebar-width-lg: 260px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

////////// GENERAL STYLES //////////
body {
  position: relative;
}
app-navbar + .page-body-wrapper {
    padding-top: $navbar-height;
}
.page-body-wrapper {
    min-height: calc(100vh - #{$navbar-height});
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    &.container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
}
.w-430 {
  max-width: 430px;
}

// Buttons
.container__uploaded-images .btn--delete-image {
  float: right;
  transform: translate(0, -50%);
  background-color: #fff;

  &:hover {
    background-color: $pink;
  }
}
// Forms
$custom-file-text: (
  en: "Browse",
  es: "Elegir"
);
.form-group > label {
  font-size: 0.875rem;
  font-weight: 600;
  vertical-align: top;
}
.form-group .form-control {
  display: inline-block;
}
.delete {
  color: #d70060 !important;
  cursor: pointer;
  margin-left: 5px;
}
.ng-select .ng-select-container {
  padding: 0.3rem 0.75rem;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  width: 10px !important;
  height: 10px !important;
}
.ng-select .ng-clear-wrapper {
  right: -16px;
  height: 18px;
}
.ng-select.ng-select-opened>.ng-select-container {
  border-color: $pink;
  box-shadow: 0 0 0 2px rgba($pink,0.2);
}
.custom-file-label {
  cursor: pointer;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.5rem;
}

// ngx-datatable styles
body  .ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: #fff;
}
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
  font-weight: 600;
}
.ngx-datatable.bootstrap .datatable-footer {
  border-top: 2px solid #d1d4d7;
  font-weight: 600;
  color: $dark !important;
  background-color: #fff !important;
}
body .ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  color: #212529;
}
body .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a,
body .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: $light;
}
.ngx-datatable .btn-sm .fa {
  width: 13px;
}
// TEMPORAL
.ngx-datatable.bootstrap .btn {
  margin-bottom: 30px;
}
